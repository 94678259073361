function tableAjaxCall(table, route, method, data, pollingroute, eventname, callback) {

    var id = table.table().node().id;
    var loading = $('.' + id +'.loading');

    if (pollingroute && eventname && window.EventSource) {
        loading.toggleClass(eventname);
        var source = new EventSource(pollingroute);
        source.addEventListener(eventname, function (event) {
            var data = JSON.parse(event.data);
            var perc = (data.partial / data.total) * 100;
            $('.' + eventname + ' #progress').text(Math.round(perc) + '%');
        });
    }

    if (!method)
       method = 'POST';

    var toastrOptions = { progressBar : false }

    loading.toggleClass('hide');

    $.ajax({
        type: method,
        url: route,
        data: data,
        dataType: 'json',
        success: function (data) {
            if(callback instanceof Function)
                callback(data);
            if(data.message)
                toastr.success(data.message, '', toastrOptions);
            toastrOptions.onHidden = null;
            table.draw(false);

            loading.toggleClass('hide');
            if (pollingroute && eventname && window.EventSource) {
                loading.toggleClass(eventname);
                source.close();
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            /*if(jqXHR.status == 401)
                window.location.href = '/login';*/

            var data = JSON.parse(jqXHR.responseText);
            if(data.message)
                toastr.error(data.message);
            loading.toggleClass('hide');
            if (pollingroute && eventname && window.EventSource) {
                loading.toggleClass(eventname);
                source.close();
            }
        }
    });
}

function ajaxCall(route, method, data, successCallback, errorCallback, dataTable, processData, contentType) {
    $.ajax({
        type: method,
        url: route,
        data: data,
        dataType: 'json',
        processData: processData ?? true,
        contentType: contentType ?? 'application/x-www-form-urlencoded; charset=UTF-8',
        beforeSend: function(request) {
            request.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
            request.setRequestHeader('Accept', 'application/json');
        },
        success: function (data) {
            if(data.message) {
                toastr.success(data.message, '', {closeButton : false})
            }

            if(successCallback instanceof Function) {
                successCallback(data);
            }

            if(dataTable) {
                dataTable.draw(false);
            }
        },
        error: function (jqXHR, textStatus, errorThroll) {
            let data = jqXHR.responseJSON;
            console.log(data);
            if(data.message) {
                toastr.error(data.message, '', {closeButton : false})
            }else{
                for(let err in data){
                    let message = data[err]
                    toastr.error(message, '', {closeButton : false})
                }
            }
            if(errorCallback instanceof Function) {
                errorCallback(data);
            }
        }
    });
}